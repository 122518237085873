<template>
  <div>
    <Breadcrumbs :main="$tc('models.user.entity', 1)" :title="breadcrumbTitle"/>
    <b-container>
      <b-row>
        <b-col>
          <b-card class="mt-4">
            <b-card-body>
              <app-form :fields="fields" :submitButton="submitButton" @submitted="handleSubmit"/>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import FormsMixin from 'mixins/FormsMixin';

export default {
  name: 'EditUser',
  data() {
    return {
      modelName: 'User',
      fields: {
        name: {
          type: 'text',
          key: 'name',
          value: '',
          label: this.$t('models.user.attributes.name'),
          classes: 'col-12 py-0',
          required: true,
          valid: false
        },
        email: {
          type: 'email',
          key: 'email',
          value: '',
          label: this.$t('models.user.attributes.email'),
          classes: 'col-12 py-0',
          required: true,
          valid: false
        },
        testUser: {
          type: 'check',
          key: 'testUser',
          value: '',
          label: this.$t('models.user.attributes.testUser'),
          classes: 'col-12 py-0',
          required: true,
          valid: false
        }
      }
    };
  },
  computed: {
    ...mapGetters({
      record: 'user'
    })
  },
  methods: {
    ...mapActions({
      getRecord: 'getUser',
      updateRecord: 'updateUser'
    })
  },
  mixins: [FormsMixin]
};
</script>
